@import "reset";
@import "fonts";

html,
body {
  height: 100%;
}

body {
  background-color: #000;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
}

#app {
  width: 100%;
  height: 100%;
  overflow: scroll;

  display: flex;
  // gap: 20px;
  font-family: "Lars", sans-serif;

  color: #fff;
  font-size: 14px;
  line-height: 1.35em;
}

.container {
  flex: 0 0 100%;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.image-container {
  width: 100%;
  aspect-ratio: 1;
  margin-bottom: 50px;
}

img {
  object-fit: contain;
  display: block;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-color: #222;
}

.description-container {
  flex: 0 1 auto;
  overflow: scroll;
  padding: 10px 0;
  mask-image: linear-gradient(
    to bottom,
    transparent,
    #000 10px,
    #000 calc(100% - 10px),
    transparent
  );
}

h1 {
  font-weight: 400;
  font-family: "Lars Mono";
  color: #00ffcc;
  margin-top: auto;
}
