html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: Lars;
  font-weight: 400;
  src: url("Lars-Regular.214acd5d.woff2") format("woff2"), url("Lars-Regular.c43a8ba5.woff") format("woff");
}

@font-face {
  font-family: Lars;
  font-weight: 700;
  src: url("Lars-Medium.3e552125.woff2") format("woff2"), url("Lars-Medium.aa851172.woff") format("woff");
}

@font-face {
  font-family: Lars Mono;
  font-weight: 400;
  src: url("Lars-Mono.04ae104a.woff2") format("woff2"), url("Lars-Mono.b594ffe0.woff") format("woff");
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  background-color: #000;
  overflow: hidden;
}

#app {
  color: #fff;
  width: 100%;
  height: 100%;
  font-family: Lars, sans-serif;
  font-size: 14px;
  line-height: 1.35em;
  display: flex;
  overflow: scroll;
}

.container {
  box-sizing: border-box;
  flex-direction: column;
  flex: 0 0 100%;
  gap: 1em;
  width: 100%;
  height: 100%;
  padding: 25px;
  display: flex;
  box-shadow: 0 0 10px #0000001a;
}

.image-container {
  aspect-ratio: 1;
  width: 100%;
  margin-bottom: 50px;
}

img {
  object-fit: contain;
  background-color: #222;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: block;
}

.description-container {
  flex: 0 auto;
  padding: 10px 0;
  overflow: scroll;
  -webkit-mask-image: linear-gradient(#0000, #000 10px calc(100% - 10px), #0000);
  mask-image: linear-gradient(#0000, #000 10px calc(100% - 10px), #0000);
}

h1 {
  color: #0fc;
  margin-top: auto;
  font-family: Lars Mono;
  font-weight: 400;
}

/*# sourceMappingURL=index.e5683b38.css.map */
