@font-face {
  font-family: "Lars";
  font-weight: 400;
  src: url("../fonts/Lars-Regular.woff2") format("woff2"),
    url("../fonts/Lars-Regular.woff") format("woff");
}

@font-face {
  font-family: "Lars";
  font-weight: 700;
  src: url("../fonts/Lars-Medium.woff2") format("woff2"),
    url("../fonts/Lars-Medium.woff") format("woff");
}

@font-face {
  font-family: "Lars Mono";
  font-weight: 400;
  src: url("../fonts/Lars-Mono.woff2") format("woff2"),
    url("../fonts/Lars-Mono.woff") format("woff");
}
